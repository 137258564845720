.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

.sectionTop {
	width: 80vw;
	display: flex;
    flex-direction: column;
	justify-content: center;
	margin-bottom: 1rem;
	> * {
		margin-bottom: 1rem;
	}
}

.background {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.h2 {
	font-size: 1.5rem;
	color: white;
	text-align: center;
	font-family: 'Montserrat';
}

.row {
	display: flex;
	align-items: center;
}

.button_row {
	display: flex;
	align-items: center;
	margin: 1rem 10vw 0 auto;
}

.inputContainer {
	width: 50%;
}


// header 
.header {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
	button {
		font-size: 0.9rem;
		white-space: nowrap;
	}
}

.row {
	display: flex;
	align-items: center;
}
.info {
  width: 60%;
  margin: 0 auto;
  p {
    margin-bottom: 0;
  }
}
.link {
  margin-right: 0.4rem;
}

.logo {
  object-fit: contain;
  width: 7rem;
}

.headerButtonOutline {
	padding: 0.8rem 1rem;
	background: white;
	border: 2px solid #002664;
	border-radius: 5px;
	margin-right: 1rem;
}

.headerButton {
	padding: 0.8rem 1rem;
	background: #c53140;
	border: none;
	color: white;
	border-radius: 5px;
}

@media only screen and (max-width: 500px),
			screen and (max-height: 500px) {
  .button_row {
	margin: 1rem 0 0 auto;
  }
}