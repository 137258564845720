.flex {
	display: flex;
	width: 100%;
}
.half {
	width: 50%;
}

.cancel {
	margin-left: 1rem;
}

.mrgnSml {
  margin-bottom: 0.8rem;
}
.mrgn {
  margin-bottom: 2rem;
}

.buttonContainer {
  display: flex;
  width: 80vw;
}
