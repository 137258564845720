.container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	background-color: #F4F4F7;
	align-items: center;
	padding: 1.4rem 2rem 1.4rem 2rem;
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 1rem;
	border-radius: 0.3rem;
	margin-bottom: 0.8rem;
	h5 {
		margin: 0;
		font-size: inherit;
	}
	* {
		width: 20%;
		text-align: left; 
		white-space: nowrap;
  		overflow: hidden;
  		text-overflow: ellipsis;
	}
}

.email {
	width: 30%;
}

.edit, .edit:focus, .edit:hover {
	color: #002664;
	width: 3rem;
}

.remove, .remove:focus, .remove:hover {
	color: #C53040;
	width: 5rem;
}

.edit, .remove {
	text-decoration: none;
	cursor: pointer;
	text-align: right;
}
