.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.logo {
	width: 6rem;
}

.visitation_logo {
	width: 6rem;
	margin: 0 auto;
	display: block;
}

.header {
	background: #151515;
	width: 100%;
	height: 15rem;
	padding: 2rem 3rem;
	color: white;
}

.headerTop, .headerBottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerTopFlex {
	flex: 1 1 0%;
}

.participants {
	position: absolute;
	top: 0.5rem;
	right: 0.8rem;
	color: white;
	font-family: Arial;
	font-size: 0.8rem;
}

.headerBottom {
	padding: 2rem 0;
}

.row {
	display: flex;
	align-items: center;
}

.row {
	display: flex;
	align-items: center;
}

.footer {
	background: #151515;
	height: 5rem;
	width: 100%;
	display: flex;
	justify-content: center;
}

.footer .row {
	transform: translateY(-50%);
}

.videoContainer {
	// background: #002664;
	background: #151515;
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative
}
.video {
	position: absolute;
    transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	height: 100%;
	width: 100%;
}

.selfView {
	position: absolute; 
    width: 25vw;
    height: 14vw;
	left: 0;
	top: 0;
}

.presentationView {
	position: absolute; 
    width: 25vw;
    height: 14vw;
	right: 0;
	top: 0;
}

.line {
    width: 0;
    height: 0.2rem;
    background: white;
    border-radius: 1rem;
    position: absolute;
    transform: rotate(-30deg);
    transition: all 0.2s ease-out;
}

.lineOff {
    width: 4rem;
}

.modalRowContainer {
	max-height: 20rem;
	overflow: auto;
	margin-bottom: 1rem;
}

.modalRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: 'Montserrat';
	margin: 0.8rem 0;
	button {
		background: none;
		border: none;
		color: #c53040;
		padding: 0.2rem;
	}
	div {
		font-family: 'Montserrat';
		font-weight: 600;
	}
}

.callButtonSmall {
	width: 6rem !important;
	height: 6rem !important;
}

.iconMic {
	height: 2rem !important;
}

.iconPhone {
	width: 4rem !important;
}

.iconCamera {
	height: 1.5rem !important;
}

.callButtonLarge {
	width: 8rem !important;
    height: 8rem !important;
	margin: 0 2rem !important;
}

@media only screen and (max-width: 500px),
			screen and (max-height: 500px) {
	.header {
		padding: 1rem;
		padding-bottom: 0;
	}

	.headerTopFlex {
		flex: none;
	}

	.visitation_logo {
		display: none;
	}

	.headerBottom {
		padding: 1rem 0;
	}

	.autoAnswerText {
		font-size: 0.8rem !important;
	}
}

@media only screen and (max-height: 500px) {
	.callButtonSmall {
		width: 3rem !important;
		height: 3rem !important;
	}

	.iconMic {
		height: 1.5rem !important;
	}

	.iconPhone {
		width: 3rem !important;
	}

	.iconCamera {
		height: 1rem !important;
	}

	.callButtonLarge {
		width: 4rem !important;
		height: 4rem !important;
	}

	.footer {
		background: none;
		height: 2.5rem;
		position: absolute;
		bottom: 0;
	}

	.headerBottom {
		padding: 0.4rem 0 0.4rem 0;
	}

	.selfView {
		width: 20vw;
	}

	.videoContainer video {
		padding-bottom: 2.5rem;
	}
}