@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

html, body {
  font-size: 16px;
  margin: 0;
  line-height: 1.2;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

input {
  box-sizing: border-box;
}

.react-search-box-dropdown {
  max-height: 12rem  !important;
  overflow-y: scroll;
  z-index: 4;
}
.react-search-box-dropdown-list-item:hover {
	font-weight: bold ;
	background-color: #e9e9f0  !important;
}

.dropdown-item:hover {
    font-weight: bold;
    background-color: #e9e9f0;
}
.btn:focus {
    box-shadow: none !important;
}

#dragswitch-handle {
    height: 1.8rem;
    width: 1.8rem;
}

@media only screen and (max-width: 700px),
            screen and (max-height: 700px) {
  html, body {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px),
            screen and (max-height: 600px) {
  html, body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px),
            screen and (max-height: 500px) {
  html, body {
    font-size: 13px;
  }
}