.loginContainer {
  padding-top: 3rem;
  position: relative;
  min-height: 82vh;

  button {
    position: absolute;
    bottom: 2rem;
  }
}

.loginGrid {
  width: 80vw;
}

.mrgnSml {
  margin-bottom: 0.8rem;
}

.mrgn {
  margin-bottom: 2.5rem;
}

@media only screen and (max-height: 400px) {
  .loginContainer {
    button {
      position: relative;
      bottom: 0;
    }
  }
}
