.container {
  padding-top: 3rem;
  position: relative;
  min-height: 82vh;
  width: 100%;

  .nextButton {
    position: absolute;
    bottom: 2rem;
  }
}

@media only screen and (max-height: 400px) {
  .container {
    .nextButton {
      position: relative;
      bottom: 0;
    }
  }
}