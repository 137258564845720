.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    background: linear-gradient(45deg, #113c81, #09245d, #113c81);
}

.logo {
	position: absolute;
	left: 4rem;
	top: 4rem;
	width: 8rem;
}

.message {
	font-family: 'Montserrat';
	position: absolute;
	transform: translateY(-10rem);
	font-size: 4rem;
	text-align: center;
	color: white;
}

.buttonContainer {
	position: absolute;
	bottom: 6rem;
	display: flex;
	width: 90%;
	max-width: 30rem;
	justify-content: space-between;
}