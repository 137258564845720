.container {
	width: 100%;
	height: 4rem;
	padding: 0 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	font-weight: 700;
	color: white;
	text-align: center;
	margin-bottom: 1rem;
}

.copy {
	margin: 0 1rem;
}