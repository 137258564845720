.loginContainer {
  padding-top: 3rem;
  position: relative;
  min-height: 82vh;
}
.loginGrid {
  width: 80vw;
}
.mrgnSml {
  margin-bottom: 0.8rem;
}
.mrgn {
  margin-bottom: 2.5rem;
}
.description {
  width: 50%;
}